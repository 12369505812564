<template>
  <div>
    <div class="store" :class="`store--brand__${store.brand.toLowerCase()}`">
      <div class="store--inner">
        <h3>{{ store.name }}</h3>
        <p v-if="content[`branchTypeAdvisory${capitalCase(store.branchType)}`]" :class="`store--brand__${store.branchType}`">
          {{ content[`branchTypeAdvisory${capitalCase(store.branchType)}`] }}
        </p>
        <p>Distance: {{ store.distanceInMiles }} miles</p>
      </div>
    </div>

    <div v-if=!IsPayPoint>
    <div v-if="availableFrom !== null">
      <div v-if="store.haveCurrency && type !== SELL_ID" class="store--stock available">
        <p>In Stock - Collect from {{ availableFrom }}</p>
      </div>
      <div v-else>
        <p>Collect in branch {{ availableFrom }}</p>
      </div>
    </div>
    <div v-else>
      <div class="store--stock closed">
        <p>Sorry we're closed this week!</p>
      </div>
    </div>
   </div>

    <div
      v-if="selectedStore && store.id === selectedStore.id"
      class="store--info"
    >
      <times :storeId="store.id" />

      <div class="store--address">
        <b>Collection address</b>

        <div class="address">
          <span
            v-if="
              selectedStore.brandName ||
              (selectedStore.brand && selectedStore.name)
            "
          >
            {{ selectedStore.brandName || selectedStore.brand }}
            {{ selectedStore.name }},
          </span>
          <span v-if="address !=null">
            <span v-if="address.address1"> {{ address.address1 }}, </span>
            <span v-if="address.address2"> {{ address.address2 }}, </span>
            <span v-if="address.address3"> {{ address.address3 }}, </span>
            <span v-if="address.address4"> {{ address.address4 }}, </span>
            <span v-if="address.postcode">{{ address.postcode }}</span>
          </span>
        </div>
        <div class="brand" :class="selectedStore.brand"></div>
      </div>
    </div>

    
    <span v-if="IsPayPoint && availableFrom !== null" class="button button--small button--show-stores pp-stores">
       <div v-if="availableFrom == 'today'"> 
       <p class="pp-stor">In Stock - <span class="pp-collect"> Collect from {{ availableFrom }} </span></p>
       </div>
       <div v-else-if="availableFrom == 'tomorrow' || availableFrom == '2pm tomorrow'">
       <p class="pp-stor"><span class="pp-collect"> Collect from {{ availableFrom }} </span></p>
       </div>   
       <div v-else>
       <p class="pp-stor"><span class="pp-collect"> Collect {{ availableFrom }} </span></p>
       </div>    
    </span>
            
    
    <button
      v-if="!store.haveNoOpeningTimes"
      class="button button--small button--show-stores"
      :class="{ 'active': selectedStore == store, 'pp-popup pp-showInfo' : IsPayPoint }"
      @click="toggleSelectedStore(store)"
    >

      {{ selectedStore == store ? 'Hide branch information &#9650;' : 'Show branch information &#9660;' }}
    </button>

    <button
      v-if="!store.haveNoOpeningTimes"  
      class="button button--small button--primary"
      :class="{ 'pp-confirm' : IsPayPoint }"
      @click="confirmStoreSelection"
    >
      Confirm
    </button>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { createHelpers } from 'vuex-map-fields'

import { DateTime } from 'luxon'
import { capitalCase } from 'change-case'
import { getStoreAddress } from '@vue/services/api/store.api'
import Times from '@vue/common/components/times'
import { SELL_ID } from '@vue/common/constants/config'
import { SITE_NAME } from '@vue/common/constants/config'

const { mapFields } = createHelpers({
  getterType: 'store/getField',
  mutationType: 'store/updateField',
})

export default {
  name: 'Store',
  props: ['store'],
  data() {
    return {
      SELL_ID,
      SITE_NAME,
      IsPayPoint:false,
      address: null,
      capitalCase: capitalCase
    }
  },
  components: {
    Times,
  },
  computed: {
    ...mapFields(['selectedStore']),
    ...mapState({
      type: (state) => state.calculator.type,
      content: (state) => state.calculator.content
    }),
    availableFrom() { 
      if (!this.store.availableFrom) {
        return null
      }
       
      const today = DateTime.now().toRelativeCalendar()
      const tomorrow = DateTime.now().plus({ days: 1 }).toRelativeCalendar()
      const availableFrom = DateTime.fromISO(this.store.availableFrom)
    
      if(this.type == SELL_ID) {
      if (today === availableFrom.toRelativeCalendar()) {
        return 'today'
      } 
      else if (tomorrow === availableFrom.toRelativeCalendar()) {
        return  'tomorrow'         
      } else {
        const dateArr = availableFrom.toFormat('EEEE d LLLL').match(/[^\s,]+/g)
        const ordinal = this.getOrdinal(dateArr[1])
        return ` on ${dateArr[0]} ${dateArr[1]}${ordinal} ${dateArr[2]}`
      }
    }
    else 
    {
      if (today === availableFrom.toRelativeCalendar()) {
        return 'today'
      } else if (tomorrow === availableFrom.toRelativeCalendar()) {
        return  this.store.haveCurrency ? 'tomorrow' : '2pm tomorrow'           
      } else {
        const dateArr = availableFrom.toFormat('EEEE d LLLL').match(/[^\s,]+/g)
        const ordinal = this.getOrdinal(dateArr[1])
        return this.store.haveCurrency ? ` on ${dateArr[0]} ${dateArr[1]}${ordinal} ${dateArr[2]}` : `from 2pm on ${dateArr[0]} ${dateArr[1]}${ordinal} ${dateArr[2]}`
      }
    }
    },
  },
  methods: {
    ...mapActions({
      toggleResult: 'calculator/toggleResult',
      toggleStoreModal: 'calculator/toggleStoreModal',
    }),
    ...mapMutations({
      setStore: 'store/setStore',
    }),
    confirmStoreSelection() {
      document.body.removeAttribute('class');
      this.setStore(this.store)
      this.toggleStoreModal(false)
      this.toggleResult(true)
    },
    toggleSelectedStore(store) {
      if(this.selectedStore == store) {
        this.selectedStore = null
      } else {
        this.selectedStore = store
      }
    },
    getOrdinal(day) {
      if (day > 3 && day < 21) return 'th'
      switch (day % 10) {
        case 1:
          return 'st'
        case 2:
          return 'nd'
        case 3:
          return 'rd'
        default:
          return 'th'
      }
    }
  },
  mounted() {
    getStoreAddress(this.store.id).then((response) => {
      this.address = response.data
    })
    this.IsPayPoint = (SITE_NAME == 'PAYPOINT')
  },
}
</script>

<style lang="scss">
.button--show-stores {
  width: 100%;
  background: #A9A9A9;
  border: solid 1px #A9A9A9;

  color: #fff;

  &:hover {
    background: darken(#A9A9A9, 20);
    cursor: pointer;
  }

}
.pp-stores {
  background:#fff !important; 
  border: none;
  margin-top: 8px !important; 
}
.pp-stor{
  color:#93d500;text-align: center
}
.pp-popup{  
    margin-top: 8px !important; 
    color: #007ea0 !important; 
}
.pp-confirm{  
    margin-top: 8px !important; 
}
.pp-showInfo{
  background: #ffff;
  width: 100%;
  border: 1px solid #007ea0 !important; 
   
  &:hover {
     color: #fff !important; 
    cursor: pointer;
  }
}
.pp-collect {
  color: #000000;
}
</style>
